<!-- eslint-disable vue/max-attributes-per-line -->
<template>
  <div>
    <validation-observer ref="loginValidation">
      <b-card>
        <b-form
            class="auth-login-form mt-2"
            @submit.prevent
            enctype="multipart/form-data"
        >
          <div class="row">
            <!-- $store.state.base_url + 'images/CIT-13-Years.jpg' -->
            <div class="col-md-6">
              <b-form-group label="About Title" label-for="title">
                <validation-provider
                    #default="{ errors }"
                    name="title"
                    rules="required"
                >
                  <b-form-input
                      id="title"
                      v-model="from.title"
                      :state="errors.length > 0 ? false : null"
                      name="title"
                      placeholder="Enter About Title"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group label="About Meta Title" label-for="meta_title">
                <validation-provider
                    #default="{ errors }"
                    name="meta_title"
                    rules="required"
                >
                  <b-form-input
                      id="meta_title"
                      v-model="from.meta_title"
                      :state="errors.length > 0 ? false : null"
                      name="title"
                      placeholder="Enter About Meta Title"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group label="About Description" label-for="description">
                <validation-provider
                    #default="{ errors }"
                    name="description"
                    rules="required"
                >
                  <Input
                      type="textarea"
                      id="description"
                      v-model="from.description"
                      :state="errors.length > 0 ? false : null"
                      name="description"
                      placeholder="Enter About Description"
                      :autosize="{ minRows: 4, maxRows: 4 }"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group label="About Meta Description" label-for="meta_description">
                <validation-provider
                    #default="{ errors }"
                    name="meta_description"
                    rules="required"
                >
                  <Input
                      type="textarea"
                      id="meta_description"
                      v-model="from.meta_description"
                      :state="errors.length > 0 ? false : null"
                      name="meta_description"
                      placeholder="Enter About Meta Description"
                      :autosize="{ minRows: 4, maxRows: 4 }"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-md-12">
              <div class="border rounded p-2 mb-2">
                <b-media
                    no-body
                    vertical-align="center"
                    class="flex-column flex-md-row"
                >
                  <b-media-aside>
                    <b-img
                        :src="about_image"
                        height="174"
                        width="310"
                        class="rounded mr-2 mb-1 mb-md-0"
                    />
                  </b-media-aside>
                  <b-media-body>
                    <b-form-group label="Video Image" label-for="about_image">
                      <b-form-file
                          id="about_image"
                          accept=".jpg, .png, .gif"
                          @change="loadImage($event)"
                          placeholder="Choose a file or drop it here..."
                          drop-placeholder="Drop file here..."
                      />
                    </b-form-group>
                    <!--email-->
                  </b-media-body>
                </b-media>
              </div>
            </div>
          </div>
          <!-- submit buttons -->
          <b-button type="submit" variant="primary" @click="validationForm">
            Update
          </b-button>
        </b-form>
      </b-card>
    </validation-observer>
  </div>
</template>
<script>
import {
  BFormFile,
  BMediaBody,
  BMediaAside,
  BMedia,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCard,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  vSelect,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
export default {
  data() {
    return {
      from: {
        title: "",
        about_image: null,
        description:null,
        meta_title: '',
        meta_description: ''
      },
      about_image: null,
      required,
      email,
    };
  },

  mounted(){
    this.getResult();
  },

  methods: {

    loadImage(e) {
      this.from.about_image = e.target.files[0];
      let file = e.target.files[0];
      let reader = new FileReader();
      reader.onload = (e) => {
        this.about_image = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    async getResult() {
      const about = await this.callApi("get", "/app/cms/about/page");
      if(about){
        this.from.title = about?.data?.about?.title;
        this.from.description = about?.data?.about?.description;
        this.about_image = about?.data?.about?.image
            ? this.$store.state.base_url + about?.data?.about?.image
            : this.getImage(1280, 720);
        this.from.meta_title  = about?.data?.about?.meta_title;
        this.from.meta_description = about?.data?.about?.meta_description;
        this.loadImage();
      }
    },
    Update() {
      const fd = new FormData();
      if (this.from.about_image) {
        fd.append(
            "image",
            this.from.about_image,
            this.from.about_image.name
        );
      }
      fd.append("title", this.from.title);
      fd.append("description", this.from.description);
      fd.append("meta_title", this.from.meta_title);
      fd.append("meta_description", this.from.meta_description);
      fd.append("image", this.from.about_image);

      axios
          .post("/app/cms/about/page", fd)
          .then((res) => {
            this.getResult();
            this.s(res.data.message);
          })
    },
    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.Update();
        }
      });
    },
  },
  components: {
    BFormFile,
    BMedia,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCard,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BMediaAside,
    BMediaBody,
    vSelect
  },
};
</script>

<style></style>